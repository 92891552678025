import React from "react"
import PropTypes from "prop-types"
class Propuesta extends React.Component {
  render () {
    return (
      <React.Fragment>
        Nombre: {this.props.nombre}
        Porcentaje: {this.props.porcentaje}
      </React.Fragment>
    );
  }
}

Propuesta.propTypes = {
  nombre: PropTypes.string,
  porcentaje: PropTypes.number
};
export default Propuesta
