export class RestPinesRepository {
  pines = () => fetch('/rates')
    .then((resp) => resp.json())
    .then(data => data)
    .catch(error => console.log(error))
}

export class PinesRepository {
  static instance = () => new RestPinesRepository();
}
