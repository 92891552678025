import {proposalStatuses} from "../repositories/ProposalsRepository";

export class MockedResponses {

  static getPines() {
    return mockedRawPines;
  }

  static getBands() {
    return mockedRawBands;
  }

  static getCurrentPine() {
    return mockedRawCurrentPine;
  }

  static getRevision() {
    return mockedRawRevision
  }

  static getRevisions(){
    return mockedRawRevisions;
  }

  static getRises() {
    return mockedRises;
  }

}

const mockedRawBands = [
  {
    "limiteInferior": 246,
    "nombre": "Apprentice",
    "id": 1
  },
  {
    "limiteInferior": 280,
    "nombre": "Padawan 1",
    "id": 2
  },
  {
    "limiteInferior": 321,
    "nombre": "Padawan 2",
    "id": 3
  },
  {
    "limiteInferior": 368,
    "nombre": "Padawan 3",
    "id": 4
  },
  {
    "limiteInferior": 421,
    "nombre": "Padawan 4",
    "id": 5
  },
  {
    "limiteInferior": 482,
    "nombre": "Knight 1",
    "id": 6
  },
  {
    "limiteInferior": 552,
    "nombre": "Knight 2",
    "id": 7
  },
  {
    "limiteInferior": 632,
    "nombre": "Knight 3",
    "id": 8
  },
  {
    "limiteInferior": 724,
    "nombre": "Knight 4",
    "id": 9
  },
  {
    "limiteInferior": 869,
    "nombre": "Master 1",
    "id": 10
  },
  {
    "limiteInferior": 995,
    "nombre": "Master 2",
    "id": 11
  },
  {
    "limiteInferior": 1139,
    "nombre": "Master 3",
    "id": 12
  },
  {
    "limiteInferior": 1367,
    "nombre": "Master 4",
    "id": 13
  }
];

const mockedRawPines = [
  {
    "id": "3",
    "nombre": "Alejandra Alfonso",
    "legajo": "16",
    "fechaIngreso": "2009-08-21",
    "franja": "Master 3",
    "rate": 1350,
    "bruto": "$237,600.00",
    "ultimoAumento": "7.00%",
    "fechaUltimoAumento": "08-2019",
    "jardineros": [
      {
        "id": "1",
        "nombre": "Emilio Gutter"
      },
      {
        "id": "5",
        "nombre": "Hernán Wilkinson"
      }
    ],
    "propuesta": {
      "sponsorsDebiles": ["642", "1"],
      "fecha": "2016-03-25",
      "status": proposalStatuses.NOT_TREATED,
      "porcentaje": 7,
      "descripcion": "Porque se lo merece",
      "franja": "Master 4",
      "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
      "rate": "1445",
      "bruto": "$254,232.00",
      "fechaUltimoAumento": "12-2019"
    },
    "ultimosAumentos": [
      {
        "fecha": "2019-03-25",
        "porcentaje": 10,
        "descripcion": "Porque se lo merece de vuelta",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}, {"id": "5", "nombre": "Hernán Wilkinson"}],
        "franja": "Master 4",
        "esAjuste": false,
        "rate": "1445",
      },
      {
        "fecha": "2018-12-25",
        "porcentaje": 7,
        "esAjuste": false,
        "descripcion": "Porque se lo merasdasdjasjdpasijasknldlnasnkladsnkladsnkdaslnkadsnadsnkladsnkadsklnadsklandsknadsklnakdsnkadsadsnadknasdnkldskladsnkldslkasdnlkasdnlkdaslkdsnlkadsdkladsnlknsdlknklads  ece",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
        "franja": "Master 3",
        "rate": "1300",
      },
      {
        "fecha": "2018-11-25",
        "porcentaje": 7,
        "esAjuste": true,
        "descripcion": "Ajuste por inflación",
        "sponsors": [],
        "franja": "Master 3",
        "rate": "1209",
      },
      {
        "fecha": "2018-08-25",
        "porcentaje": 7,
        "esAjuste": false,
        "descripcion": "Porque si",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
        "franja": "Master 3",
        "rate": "1125",
      },
      {
        "fecha": "2018-04-25",
        "porcentaje": 7,
        "esAjuste": false,
        "descripcion": "Porque si",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
        "franja": "Master 3",
        "rate": "1046",
      },
      {
        "fecha": "2018-01-25",
        "porcentaje": 7,
        "esAjuste": true,
        "descripcion": "Ajuste por inflación",
        "sponsors": [],
        "franja": "Master 3",
        "rate": "972",
      },
      {
        "fecha": "2017-08-25",
        "porcentaje": 7,
        "esAjuste": true,
        "descripcion": "Porque si",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
        "franja": "Master 2",
        "rate": "904",
      },
      {
        "fecha": "2017-06-25",
        "porcentaje": 7,
        "esAjuste": true,
        "descripcion": "Ajuste por inflación",
        "sponsors": [],
        "franja": "Master 2",
        "rate": "841",
      },
      {
        "fecha": "2017-04-25",
        "porcentaje": 7,
        "esAjuste": false,
        "descripcion": "Porque si",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
        "franja": "Master 2",
        "rate": "782",
      },
      {
        "fecha": "2017-01-25",
        "porcentaje": 7,
        "esAjuste": false,
        "descripcion": "Porque si",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
        "franja": "Master 2",
        "rate": "727",
      },
      {
        "fecha": "2016-08-25",
        "porcentaje": 7,
        "esAjuste": false,
        "descripcion": "Porque si",
        "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
        "franja": "Master 1",
        "rate": "676",
      },
      {
        "fecha": "2016-06-25",
        "porcentaje": 7,
        "esAjuste": true,
        "descripcion": "Ajuste por inflación",
        "sponsors": [],
        "franja": "Master 2",
        "rate": "629",
      },

    ]
  },
  {
    "id": "1",
    "nombre": "Emilio Gutter",
    "legajo": "4",
    "fechaIngreso": "2009-08-21",
    "franja": "Master 3",
    "rate": "1350",
    "bruto": "$237,600.00",
    "ultimoAumento": "7.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},

    "jardineros": [
      {
        "id": "642",
        "nombre": "Ariel Umansky"
      }
    ],
    "ultimosAumentos": [],
  },
  {
    "id": "5",
    "nombre": "Hernan Wilkinson",
    "legajo": "5",
    "fechaIngreso": "2009-08-21",
    "franja": "Master 3",
    "rate": "1350",
    "bruto": "$237,600.00",
    "ultimoAumento": "7.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "ultimosAumentos": [],
    "jardineros": [],

  },
  {
    "id": "6",
    "nombre": "Jorge Silva",
    "legajo": "20",
    "fechaIngreso": "2009-08-21",
    "franja": "Master 3",
    "rate": "1350",
    "bruto": "$237,600.00",
    "ultimoAumento": "7.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "152",
    "nombre": "Dario Garcia",
    "legajo": "10",
    "fechaIngreso": "2011-01-01",
    "franja": "Master 3",
    "rate": "1350",
    "bruto": "$237,600.00",
    "ultimoAumento": "15.75%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "2",
    "nombre": "Federico Zuppa",
    "legajo": "14",
    "fechaIngreso": "2010-04-01",
    "franja": "Master 3",
    "rate": "1350",
    "bruto": "$237,600.00",
    "ultimoAumento": "15.75%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "310",
    "nombre": "Gisela Decuzzi",
    "legajo": "11",
    "fechaIngreso": "2012-03-12",
    "franja": "Master 1",
    "rate": "1043",
    "bruto": "$183,568.00",
    "ultimoAumento": "7.04%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "383",
    "nombre": "Igal Frid",
    "legajo": "12",
    "fechaIngreso": "2012-11-05",
    "franja": "Master 1",
    "rate": "1043",
    "bruto": "$183,568.00",
    "ultimoAumento": "7.04%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "556",
    "nombre": "Matias Fernandez",
    "legajo": "28",
    "fechaIngreso": "2014-07-01",
    "franja": "Master 1",
    "rate": "992",
    "bruto": "$174,592.00",
    "ultimoAumento": "7.03%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "327",
    "nombre": "Nicolas Papagna Maldonado",
    "legajo": "7",
    "fechaIngreso": "2012-06-01",
    "franja": "Master 1",
    "rate": "992",
    "bruto": "$174,592.00",
    "ultimoAumento": "7.03%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "215",
    "nombre": "Nicolas Perez Santoro",
    "legajo": "9",
    "fechaIngreso": "2011-06-01",
    "franja": "Master 1",
    "rate": "992",
    "bruto": "$174,592.00",
    "ultimoAumento": "7.03%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1361",
    "nombre": "Fernando Dodino",
    "legajo": "84",
    "fechaIngreso": "2018-08-21",
    "franja": "Knight 4",
    "rate": "850",
    "bruto": "$149,600.00",
    "ultimoAumento": "#N/A",
    "fechaUltimoAumento": "#N/A",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "409",
    "nombre": "Lucas Giudice",
    "legajo": "17",
    "fechaIngreso": "2013-02-19",
    "franja": "Knight 4",
    "rate": "828",
    "bruto": "$145,728.00",
    "ultimoAumento": "7.02%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "543",
    "nombre": "Federico Grosso",
    "legajo": "26",
    "fechaIngreso": "2014-05-21",
    "franja": "Knight 4",
    "rate": "814",
    "bruto": "$143,264.00",
    "ultimoAumento": "7.15%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "561",
    "nombre": "Martin Tabak",
    "legajo": "30",
    "fechaIngreso": "2014-07-21",
    "franja": "Knight 4",
    "rate": "810",
    "bruto": "$142,560.00",
    "ultimoAumento": "10.08%",
    "fechaUltimoAumento": "08-2018",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "140",
    "nombre": "Maximo Prieto",
    "legajo": "95",
    "fechaIngreso": "2019-07-01",
    "franja": "Knight 4",
    "rate": "800",
    "bruto": "$140,800.00",
    "ultimoAumento": "#N/A",
    "fechaUltimoAumento": "#N/A",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "307",
    "nombre": "Nahuel Garbezza",
    "legajo": "2",
    "fechaIngreso": "2012-03-07",
    "franja": "Knight 4",
    "rate": "727",
    "bruto": "$127,952.00",
    "ultimoAumento": "7.19%",
    "fechaUltimoAumento": "12-2018",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "969",
    "nombre": "Sonia Stedile",
    "legajo": "57",
    "fechaIngreso": "2016-12-19",
    "franja": "Knight 4",
    "rate": "727",
    "bruto": "$127,952.00",
    "ultimoAumento": "7.19%",
    "fechaUltimoAumento": "12-2018",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "781",
    "nombre": "David Kotlirevsky",
    "legajo": "47",
    "fechaIngreso": "2016-06-27",
    "franja": "Knight 4",
    "rate": "724",
    "bruto": "$127,424.00",
    "ultimoAumento": "7.14%",
    "fechaUltimoAumento": "11-2017",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "562",
    "nombre": "Pablo Belaustegui",
    "legajo": "29",
    "fechaIngreso": "2014-07-21",
    "franja": "Knight 4",
    "rate": "724",
    "bruto": "$127,424.00",
    "ultimoAumento": "10.70%",
    "fechaUltimoAumento": "11-2017",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "642",
    "nombre": "Ariel Umansky",
    "legajo": "36",
    "fechaIngreso": "2015-03-25",
    "franja": "Knight 3",
    "rate": "709",
    "bruto": "$124,784.00",
    "ultimoAumento": "10.04%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {
      "sponsorsDebiles": ["642", "1"],
      "fecha": "2016-03-25",
      "status": proposalStatuses.NOT_TREATED,
      "porcentaje": 7,
      "descripcion": "Porque se lo merece",
      "franja": "Master 4",
      "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}, {"id": "5", "nombre": "Hernán Wilkinson"}],
      "rate": "1445",
      "bruto": "$254,232.00",
      "fechaUltimoAumento": "12-2019"
    },
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1194",
    "nombre": "Sergio Minutoli",
    "legajo": "72",
    "fechaIngreso": "2018-03-01",
    "franja": "Knight 3",
    "rate": "681",
    "bruto": "$119,856.00",
    "ultimoAumento": "7.21%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "802",
    "nombre": "Nicolás Derecho",
    "legajo": "55",
    "fechaIngreso": "2016-08-16",
    "franja": "Knight 3",
    "rate": "671",
    "bruto": "$118,096.00",
    "ultimoAumento": "7.17%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "578",
    "nombre": "Matias Melendi",
    "legajo": "33",
    "fechaIngreso": "2014-09-08",
    "franja": "Knight 3",
    "rate": "649",
    "bruto": "$114,224.00",
    "ultimoAumento": "10.19%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "555",
    "nombre": "Julian Calvento",
    "legajo": "27",
    "fechaIngreso": "2014-06-16",
    "franja": "Knight 3",
    "rate": "632",
    "bruto": "$111,232.00",
    "ultimoAumento": "7.01%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "573",
    "nombre": "Santiago Nodar",
    "legajo": "31",
    "fechaIngreso": "2014-08-25",
    "franja": "Knight 3",
    "rate": "632",
    "bruto": "$111,232.00",
    "ultimoAumento": "7.01%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "655",
    "nombre": "Victoria Cabrera",
    "legajo": "38",
    "fechaIngreso": "2015-05-04",
    "franja": "Knight 3",
    "rate": "632",
    "bruto": "$111,232.00",
    "ultimoAumento": "8.15%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "696",
    "nombre": "Angeles Tella Arena",
    "legajo": "41",
    "fechaIngreso": "2015-11-16",
    "franja": "Knight 2",
    "rate": "623",
    "bruto": "$109,648.00",
    "ultimoAumento": "10.02%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "656",
    "nombre": "Joaquin Azcarate",
    "legajo": "37",
    "fechaIngreso": "2015-05-04",
    "franja": "Knight 2",
    "rate": "609",
    "bruto": "$107,184.00",
    "ultimoAumento": "7.21%",
    "fechaUltimoAumento": "12-2018",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1354",
    "nombre": "Ezequiel Alvarez",
    "legajo": "83",
    "fechaIngreso": "2018-08-01",
    "franja": "Knight 2",
    "rate": "593",
    "bruto": "$104,368.00",
    "ultimoAumento": "7.16%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "464",
    "nombre": "Victoria Palazesi",
    "legajo": "21",
    "fechaIngreso": "2013-10-07",
    "franja": "Knight 2",
    "rate": "589",
    "bruto": "$103,664.00",
    "ultimoAumento": "7.08%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1105",
    "nombre": "Erwin Debusschere",
    "legajo": "64",
    "fechaIngreso": "2017-09-11",
    "franja": "Knight 2",
    "rate": "571",
    "bruto": "$100,496.00",
    "ultimoAumento": "8.48%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "776",
    "nombre": "Juan Manuel Fernandes dos Santos",
    "legajo": "46",
    "fechaIngreso": "2016-06-01",
    "franja": "Knight 2",
    "rate": "562",
    "bruto": "$98,912.00",
    "ultimoAumento": "7.21%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "719",
    "nombre": "David Correa",
    "legajo": "43",
    "fechaIngreso": "2016-01-04",
    "franja": "Knight 2",
    "rate": "555",
    "bruto": "$97,680.00",
    "ultimoAumento": "7.05%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1253",
    "nombre": "Emmanuel Schönfeld",
    "legajo": "74",
    "fechaIngreso": "2018-04-09",
    "franja": "Knight 2",
    "rate": "555",
    "bruto": "$97,680.00",
    "ultimoAumento": "7.05%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1332",
    "nombre": "Facundo Gelatti",
    "legajo": "82",
    "fechaIngreso": "2018-07-02",
    "franja": "Knight 2",
    "rate": "552",
    "bruto": "$97,152.00",
    "ultimoAumento": "13.16%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "726",
    "nombre": "Agustín García Smith",
    "legajo": "44",
    "fechaIngreso": "2016-01-18",
    "franja": "Knight 1",
    "rate": "514",
    "bruto": "$90,464.00",
    "ultimoAumento": "7.21%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "800",
    "nombre": "Daniel Lovero",
    "legajo": "54",
    "fechaIngreso": "2016-08-08",
    "franja": "Knight 1",
    "rate": "514",
    "bruto": "$90,464.00",
    "ultimoAumento": "7.21%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1196",
    "nombre": "Lautaro Ary Garcia",
    "legajo": "73",
    "fechaIngreso": "2018-03-07",
    "franja": "Knight 1",
    "rate": "507",
    "bruto": "$89,232.00",
    "ultimoAumento": "10.13%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1293",
    "nombre": "Jessica Saavedra",
    "legajo": "80",
    "fechaIngreso": "2018-06-18",
    "franja": "Knight 1",
    "rate": "503",
    "bruto": "$88,528.00",
    "ultimoAumento": "10.23%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "788",
    "nombre": "Gian Franco Fioriello",
    "legajo": "48",
    "fechaIngreso": "2016-07-18",
    "franja": "Knight 1",
    "rate": "500",
    "bruto": "$88,000.00",
    "ultimoAumento": "6.41%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1281",
    "nombre": "Leandro Vidal",
    "legajo": "75",
    "fechaIngreso": "2018-06-11",
    "franja": "Knight 1",
    "rate": "500",
    "bruto": "$88,000.00",
    "ultimoAumento": "7.10%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "799",
    "nombre": "Gustavo Crespi",
    "legajo": "53",
    "fechaIngreso": "2016-08-08",
    "franja": "Knight 1",
    "rate": "498",
    "bruto": "$87,648.00",
    "ultimoAumento": "10.05%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "787",
    "nombre": "Lucas Traverso",
    "legajo": "49",
    "fechaIngreso": "2016-07-18",
    "franja": "Knight 1",
    "rate": "493",
    "bruto": "$86,768.00",
    "ultimoAumento": "7.21%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "798",
    "nombre": "Ayelén García",
    "legajo": "52",
    "fechaIngreso": "2016-08-08",
    "franja": "Knight 1",
    "rate": "490",
    "bruto": "$86,240.00",
    "ultimoAumento": "7.26%",
    "fechaUltimoAumento": "05-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1732",
    "nombre": "Gonzalo Gras Cantou",
    "legajo": "96",
    "fechaIngreso": "2019-09-02",
    "franja": "Knight 1",
    "rate": "483",
    "bruto": "$85,008.00",
    "ultimoAumento": "#N/A",
    "fechaUltimoAumento": "#N/A",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1028",
    "nombre": "Ornella Bordino",
    "legajo": "63",
    "fechaIngreso": "2017-04-24",
    "franja": "Knight 1",
    "rate": "482",
    "bruto": "$84,832.00",
    "ultimoAumento": "7.12%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "506",
    "nombre": "Pablo Alegre",
    "legajo": "24",
    "fechaIngreso": "2014-02-03",
    "franja": "Padawan 4",
    "rate": "474",
    "bruto": "$83,424.00",
    "ultimoAumento": "7.26%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1652",
    "nombre": "Javier Zolotarchuk",
    "legajo": "89",
    "fechaIngreso": "05-2019-02",
    "franja": "Padawan 4",
    "rate": "459",
    "bruto": "$80,784.00",
    "ultimoAumento": "#N/A",
    "fechaUltimoAumento": "#N/A",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "720",
    "nombre": "Gastón Caruso",
    "legajo": "42",
    "fechaIngreso": "2016-01-04",
    "franja": "Padawan 4",
    "rate": "455",
    "bruto": "$80,080.00",
    "ultimoAumento": "7.29%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1108",
    "nombre": "Carlos Perez",
    "legajo": "65",
    "fechaIngreso": "2017-09-18",
    "franja": "Padawan 4",
    "rate": "452",
    "bruto": "$79,552.00",
    "ultimoAumento": "7.04%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1018",
    "nombre": "Ezequiel Seidler",
    "legajo": "60",
    "fechaIngreso": "2017-04-05",
    "franja": "Padawan 4",
    "rate": "421",
    "bruto": "$74,096.00",
    "ultimoAumento": "7.28%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "753",
    "nombre": "Cristian Boccia",
    "legajo": "45",
    "fechaIngreso": "2016-03-01",
    "franja": "Padawan 3",
    "rate": "389",
    "bruto": "$68,464.00",
    "ultimoAumento": "10.23%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1011",
    "nombre": "Federico Romero",
    "legajo": "59",
    "fechaIngreso": "2017-03-27",
    "franja": "Padawan 3",
    "rate": "378",
    "bruto": "$66,528.00",
    "ultimoAumento": "7.26%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1113",
    "nombre": "Martin Gonzalez",
    "legajo": "66",
    "fechaIngreso": "2017-09-25",
    "franja": "Padawan 3",
    "rate": "377",
    "bruto": "$66,352.00",
    "ultimoAumento": "10.20%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1010",
    "nombre": "Sandro Damilano",
    "legajo": "58",
    "fechaIngreso": "2017-03-27",
    "franja": "Padawan 3",
    "rate": "377",
    "bruto": "$66,352.00",
    "ultimoAumento": "7.28%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "791",
    "nombre": "Marcos Daniel Alvarenga",
    "legajo": "51",
    "fechaIngreso": "2016-07-26",
    "franja": "Padawan 2",
    "rate": "361",
    "bruto": "$63,536.00",
    "ultimoAumento": "7.29%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1173",
    "nombre": "Nicolas Rainhart",
    "legajo": "68",
    "fechaIngreso": "2018-01-08",
    "franja": "Padawan 2",
    "rate": "355",
    "bruto": "$62,480.00",
    "ultimoAumento": "10.14%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "594",
    "nombre": "Gabriela Iztueta",
    "legajo": "34",
    "fechaIngreso": "2014-12-05",
    "franja": "Padawan 2",
    "rate": "349",
    "bruto": "$61,424.00",
    "ultimoAumento": "5.00%",
    "fechaUltimoAumento": "07-2017",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1019",
    "nombre": "Magdalena Mileto",
    "legajo": "61",
    "fechaIngreso": "2017-04-05",
    "franja": "Padawan 2",
    "rate": "337",
    "bruto": "$59,312.00",
    "ultimoAumento": "7.06%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1171",
    "nombre": "Joel Camera",
    "legajo": "70",
    "fechaIngreso": "2018-01-08",
    "franja": "Padawan 2",
    "rate": "334",
    "bruto": "$58,784.00",
    "ultimoAumento": "7.12%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1170",
    "nombre": "Micaela Alonso",
    "legajo": "69",
    "fechaIngreso": "2018-01-08",
    "franja": "Padawan 2",
    "rate": "334",
    "bruto": "$58,784.00",
    "ultimoAumento": "10.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1290",
    "nombre": "Agustín De Cunto",
    "legajo": "78",
    "fechaIngreso": "2018-06-18",
    "franja": "Padawan 2",
    "rate": "321",
    "bruto": "$56,496.00",
    "ultimoAumento": "10.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1329",
    "nombre": "Ailen Muñoz",
    "legajo": "81",
    "fechaIngreso": "2018-06-25",
    "franja": "Padawan 2",
    "rate": "321",
    "bruto": "$56,496.00",
    "ultimoAumento": "10.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1292",
    "nombre": "Erik Gervas",
    "legajo": "79",
    "fechaIngreso": "2018-06-18",
    "franja": "Padawan 2",
    "rate": "321",
    "bruto": "$56,496.00",
    "ultimoAumento": "10.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1289",
    "nombre": "Lucas Wojciechow",
    "legajo": "76",
    "fechaIngreso": "2018-06-18",
    "franja": "Padawan 2",
    "rate": "321",
    "bruto": "$56,496.00",
    "ultimoAumento": "10.00%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1172",
    "nombre": "Daniel Fau",
    "legajo": "71",
    "fechaIngreso": "2018-01-08",
    "franja": "Padawan 1",
    "rate": "317",
    "bruto": "$55,792.00",
    "ultimoAumento": "7.09%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1291",
    "nombre": "María Luz Pebes",
    "legajo": "77",
    "fechaIngreso": "2018-06-18",
    "franja": "Padawan 1",
    "rate": "304",
    "bruto": "$53,504.00",
    "ultimoAumento": "7.41%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1554",
    "nombre": "Maria Belen Amat",
    "legajo": "88",
    "fechaIngreso": "2019-01-07",
    "franja": "Padawan 1",
    "rate": "299",
    "bruto": "$52,624.00",
    "ultimoAumento": "10.38%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1551",
    "nombre": "Gaston Testay",
    "legajo": "86",
    "fechaIngreso": "2019-01-07",
    "franja": "Padawan 1",
    "rate": "291",
    "bruto": "$51,216.00",
    "ultimoAumento": "7.08%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1553",
    "nombre": "Luca Hazuca",
    "legajo": "87",
    "fechaIngreso": "2019-01-07",
    "franja": "Padawan 1",
    "rate": "291",
    "bruto": "$51,216.00",
    "ultimoAumento": "7.08%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1552",
    "nombre": "Lucas Leonardo Trinidad",
    "legajo": "85",
    "fechaIngreso": "2019-01-07",
    "franja": "Padawan 1",
    "rate": "291",
    "bruto": "$51,216.00",
    "ultimoAumento": "7.08%",
    "fechaUltimoAumento": "08-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1657",
    "nombre": "Angel Yan",
    "legajo": "91",
    "fechaIngreso": "2019-05-15",
    "franja": "Padawan 1",
    "rate": "281",
    "bruto": "$49,456.00",
    "ultimoAumento": "4.98%",
    "fechaUltimoAumento": "09-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1659",
    "nombre": "Damian Pereira",
    "legajo": "93",
    "fechaIngreso": "2019-05-15",
    "franja": "Padawan 1",
    "rate": "281",
    "bruto": "$49,456.00",
    "ultimoAumento": "4.98%",
    "fechaUltimoAumento": "09-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1658",
    "nombre": "Santiago Paredes",
    "legajo": "92",
    "fechaIngreso": "2019-05-15",
    "franja": "Padawan 1",
    "rate": "281",
    "bruto": "$49,456.00",
    "ultimoAumento": "4.98%",
    "fechaUltimoAumento": "09-2019",
    "propuesta": {},
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1739",
    "nombre": "Ornella Mosca",
    "legajo": "97",
    "fechaIngreso": "2019-09-09",
    "franja": "Apprentice",
    "rate": "246",
    "bruto": "$43,296.00",
    "ultimoAumento": "#N/A",
    "fechaUltimoAumento": "#N/A",
    "propuesta": {
      "sponsorsDebiles": ["642"],
      "fecha": "12-2019-12",
      "status": proposalStatuses.NOT_TREATED,
      "porcentaje": 13.8,
      "descripcion": "Egreso del Apprenticeship",
      "franja": "Padawan 1",
      "sponsors": [{"id": "642", "nombre": "Ariel Umansky"}],
      "rate": "281",
      "bruto": "$49,456.00",
      "fechaUltimoAumento": "-"
    },
    "jardineros": [],

    "ultimosAumentos": [],
  },
  {
    "id": "1740",
    "nombre": "Federico Gustavo Martínez Fonseca",
    "legajo": "98",
    "fechaIngreso": "2019-09-09",
    "franja": "Apprentice",
    "rate": "246",
    "bruto": "$43,296.00",
    "ultimoAumento": "#N/A",
    "fechaUltimoAumento": "#N/A",
    "propuesta": {
      "sponsorsDebiles": ["642"],
      "fecha": "12-2019-12",
      "status": proposalStatuses.NOT_TREATED,
      "porcentaje": 13.8,
      "descripcion": "Egreso del Apprenticeship",
      "franja": "Padawan 1",
      "sponsors": [{"id": "642", "nombre": "Ariel Umansky"}],
      "rate": "281",
      "bruto": "$49,456.00",
      "fechaUltimoAumento": "-"
    },
    "jardineros": [],
    "ultimosAumentos": [],

  },
  {
    "id": "1743",
    "nombre": "Carolina Destuet",
    "legajo": "99",
    "fechaIngreso": "2019-09-16",
    "franja": "Apprentice",
    "rate": "246",
    "bruto": "$43,296.00",
    "ultimoAumento": "#N/A",
    "fechaUltimoAumento": "#N/A",
    "propuesta": {
      "sponsorsDebiles": ["642"],
      "fecha": "12-2019-12",
      "status": proposalStatuses.NOT_TREATED,
      "porcentaje": 13.8,
      "descripcion": "Egreso del Apprenticeship",
      "franja": "Padawan 1",
      "sponsors": [{"id": "642", "nombre": "Ariel Umansky"}],
      "rate": "281",
      "bruto": "$49,456.00",
      "fechaUltimoAumento": "-"
    },
    "jardineros": [],
    "ultimosAumentos": [],

  }
];

const mockedRawCurrentPine = {
  "id": "642",
  "nombre": "Ariel Umansky",
  "legajo": "36",
  "fechaIngreso": "2015-03-25",
  "franja": "Knight 3",
  "rate": "709",
  "bruto": "$124,784.00",
  "ultimoAumento": "10.04%",
  "fechaUltimoAumento": "08-2019",
  "propuesta": {
    "sponsorsDebiles": ["642", "1"],
    "fecha": "2016-03-25",
    "status": proposalStatuses.NOT_TREATED,
    "porcentaje": 7,
    "descripcion": "Porque se lo merece",
    "franja": "Master 4",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}, {"id": "5", "nombre": "Hernán Wilkinson"}],
    "rate": "1445",
    "bruto": "$254,232.00",
    "fechaUltimoAumento": "12-2019"
  },
  "ultimosAumentos": [
    {
      "fecha": "2016-03-25",
      "porcentaje": 7,
      "descripcion": "Porque se lo merece",
      "idSponsor": 1,
      "franja": "Master 4",
      "nombreSponsor": "Emilio Gutter",
      "rate": "1445",
      "bruto": "$254,232.00",
      "fechaUltimoAumento": "12-2019"
    }
  ]
};

const mockedRawRevision = {
  fecha: new Date(),
  estaAbierta: true,
  pinoActualEnRevisionId: "1743", //TODO: Este es un Apprentice. Actualizar esto cuando venga la data del backend,
  esAjuste: false
};

const mockedRawRevisions = [
  mockedRawRevision,
  {
    fecha: new Date(2019, 10, 20),
    estaAbierta: false,
    pinoActualEnRevisionId: "1",
    esAjuste: true
  },
  {
    fecha: new Date(2019, 8, 20),
    estaAbierta: false,
    pinoActualEnRevisionId: "1",
    esAjuste: false
  },
  {
    fecha: new Date(2019, 4, 20),
    estaAbierta: false,
    pinoActualEnRevisionId: "11",
    esAjuste: false
  }
];

const mockedRises = [
  {
    "fecha": "2019-03-25",
    "porcentaje": 10,
    "descripcion": "Porque se lo merece de vuelta",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}, {"id": "5", "nombre": "Hernán Wilkinson"}],
    "franja": "Master 4",
    "esAjuste": false,
    "rate": "1445",
  },
  {
    "fecha": "2018-12-25",
    "porcentaje": 7,
    "esAjuste": false,
    "descripcion": "Porque se lo merasdasdjasjdpasijasknldlnasnkladsnkladsnkdaslnkadsnadsnkladsnkadsklnadsklandsknadsklnakdsnkadsadsnadknasdnkldskladsnkldslkasdnlkasdnlkdaslkdsnlkadsdkladsnlknsdlknklads  ece",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
    "franja": "Master 3",
    "rate": "1300",
  },
  {
    "fecha": "2018-11-25",
    "porcentaje": 7,
    "esAjuste": true,
    "descripcion": "Ajuste por inflación",
    "sponsors": [],
    "franja": "Master 3",
    "rate": "1209",
  },
  {
    "fecha": "2018-08-25",
    "porcentaje": 7,
    "esAjuste": false,
    "descripcion": "Porque si",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
    "franja": "Master 3",
    "rate": "1125",
  },
  {
    "fecha": "2018-04-25",
    "porcentaje": 7,
    "esAjuste": false,
    "descripcion": "Porque si",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
    "franja": "Master 3",
    "rate": "1046",
  },
  {
    "fecha": "2018-01-25",
    "porcentaje": 7,
    "esAjuste": true,
    "descripcion": "Ajuste por inflación",
    "sponsors": [],
    "franja": "Master 3",
    "rate": "972",
  },
  {
    "fecha": "2017-08-25",
    "porcentaje": 7,
    "esAjuste": true,
    "descripcion": "Porque si",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
    "franja": "Master 2",
    "rate": "904",
  },
  {
    "fecha": "2017-06-25",
    "porcentaje": 7,
    "esAjuste": true,
    "descripcion": "Ajuste por inflación",
    "sponsors": [],
    "franja": "Master 2",
    "rate": "841",
  },
  {
    "fecha": "2017-04-25",
    "porcentaje": 7,
    "esAjuste": false,
    "descripcion": "Porque si",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
    "franja": "Master 2",
    "rate": "782",
  },
  {
    "fecha": "2017-01-25",
    "porcentaje": 7,
    "esAjuste": false,
    "descripcion": "Porque si",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
    "franja": "Master 2",
    "rate": "727",
  },
  {
    "fecha": "2016-08-25",
    "porcentaje": 7,
    "esAjuste": false,
    "descripcion": "Porque si",
    "sponsors": [{"id": "1", "nombre": "Emilio Gutter"}],
    "franja": "Master 1",
    "rate": "676",
  },
  {
    "fecha": "2016-06-25",
    "porcentaje": 7,
    "esAjuste": true,
    "descripcion": "Ajuste por inflación",
    "sponsors": [],
    "franja": "Master 2",
    "rate": "629",
  },

];