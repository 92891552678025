import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const iconStyle = {
  marginRight: "1rem",
  marginLeft: "1rem",
  cursor: "pointer"
};

export class ProposalDataIcon extends React.Component {

  handleClick = () => {
    this.props.onIconClick(this.props.pine);
  };

  render() {
    return (
      <div onClick={this.handleClick}>
        <FontAwesomeIcon style={iconStyle} icon={this.props.icon} color={this.props.isEnabled ? this.props.enabledColor : "rgb(80, 80, 80)"} size="2x"/>
      </div>
    );
  }
}
